.HomepageHeroGradient {
  height: 50vh;
  width: 100%;
  position: relative;
  min-height: 12rem;
  max-height: 25rem;
  overflow: hidden;
  font-family: 'Audiowide', 'Russo One', 'Black Han Sans', 'Rowdies',
    'ADLaM Display', 'Archivo Black', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
}
.Gradient__canvas {
  position: relative;
  height: 50vh;
  min-height: 12rem;
  max-height: 25rem;
  width: 100%;
}

.HomepageHeroHeader {
  position: absolute;
  z-index: 3;
  color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background-image: linear-gradient(
    to right bottom,
    #4b24fb,
    hsla(251, 96%, 56%, 0.3),
    hsla(251, 96%, 56%, 0.2),
    transparent,
    transparent
  );
  border-radius: 3.125rem 3.125rem 3.125rem 0;
}
.EmptyBox1 {
  position: absolute;
  top: 25%;
  left: 35%;
  transform: translate(-50%, -50%);
  min-width: 9.375rem;
  min-height: 9.375rem;
  z-index: 2;
  border-radius: 31.25rem 31.25rem 0 31.25rem;
  background-image: linear-gradient(
    to right bottom,
    #4b24fb,
    #bd2fdb,
    #ec5fc6,
    #ff95c0,
    #ffcad2
  );
}
.EmptyBox2 {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  min-width: 15.625rem;
  min-height: 15.625rem;
  z-index: 2;
  border-radius: 31.25rem 31.25rem 31.25rem 0;
  background-image: linear-gradient(
    to right,
    #4b24fb,
    #bd2fdb,
    #ec5fc6,
    #ff95c0,
    #ffcad2
  );
}
.EmptyBox3 {
  position: absolute;
  top: 45%;
  left: 85%;
  transform: translate(-50%, -50%);
  min-width: 16.625rem;
  min-height: 16.625rem;
  z-index: 1;
  border-radius: 31.25rem 31.25rem 31.25rem 0;
  background-image: linear-gradient(
    to left bottom,
    #90e0ff,
    hsla(197, 100%, 78%, 0.3),
    hsla(197, 100%, 78%, 0.1),
    transparent,
    transparent
  );
}

.HomepageHeroHeader__title {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-shadow: 0 0 3px #000;
}

.HomepageHeroHeader__caption {
  font-size: 1.5rem;
  text-shadow: 0 0 3px #000;
}

/*---Waves---*/

.waves-container {
  position: relative;
  z-index: 100;
  bottom: 3rem;
  left: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  max-height: 100px;
}

.waves-container .waves {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Waves animation */
.waves-container .parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.waves-container .parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.waves-container .parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.waves-container .parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.waves-container .parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
@media screen and (max-width: 40rem) {
  .HomepageHeroGradient {
    height: 35vh;
  }
  .Gradient__canvas {
    height: 35vh;
  }
  .HomepageHeroHeader__title {
    font-size: 1.5rem;
    white-space: nowrap;
  }

  .HomepageHeroHeader__caption {
    font-size: 1rem;
  }
}
